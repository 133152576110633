<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="消息链路id" prop="traceId">
                <a-input v-model="queryParam.traceId" placeholder="请输入消息链路id" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="模板 id" prop="templateId">
                <a-input v-model="queryParam.templateId" placeholder="请输入模板 id" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="消息应用id" prop="appId">
                  <a-input v-model="queryParam.appId" placeholder="请输入消息应用id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="消息状态" prop="messageStatus">
                  <a-select placeholder="请选择消息状态" v-model="queryParam.messageStatus" style="width: 100%" allow-clear>
                    <a-select-option
                    v-for="(d, index) in msgStatusOptions"
                    :key="index"
                    :value="parseInt(d.dictValue)"
                    >{{ d.dictLabel }}</a-select-option
                  >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="用户类型" prop="userType">
                  <a-select placeholder="请选择用户类型" v-model="queryParam.userType" style="width: 100%" allow-clear>
                    <a-select-option
                      v-for="(d, index) in msgUserTypeOptions"
                      :key="index"
                      :value="parseInt(d.dictValue)"
                      >{{ d.dictLabel }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="消息推送人" prop="pushUser">
                  <a-input v-model="queryParam.pushUser" placeholder="请输入消息推送人" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="渠道选择" prop="channelType">
                  <a-select
                    placeholder="请选择"
                    v-model="queryParam.channelType"
                    @change="onChangeChannelType"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option
                      v-for="(d, index) in msgChannelTypeOptions"
                      :key="index"
                      :value="parseInt(d.dictValue)"
                      >{{ d.dictLabel }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="内容类型" prop="messageType">
                  <a-select placeholder="请选择" v-model="queryParam.messageType" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in messageTypeOptions" :key="index" :value="d.code">
                      {{ d.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="推送范围" prop="pushRange">
                <a-select placeholder="请输入推送范围" v-model="queryParam.pushRange" style="width: 100%" allow-clear>
                  <a-select-option
                    v-for="(d, index) in msgPushScopeOptions"
                    :key="index"
                    :value="parseInt(d.dictValue)"
                    >{{ d.dictLabel }}</a-select-option
                  >
                </a-select>
              </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否重试消息" prop="retried">
                  <a-select placeholder="请输入是否重试消息" v-model="queryParam.retried" style="width: 100%" allow-clear>
                    <a-select-option
                    v-for="(d, index) in retriedOptions"
                    :key="index"
                    :value="parseInt(d.dictValue)"
                    >{{ d.dictLabel }}</a-select-option
                  >
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!-- <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:message:messageRecord:add']">
          <a-icon type="plus" />新增
        </a-button> -->
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['tenant:message:messageRecord:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['tenant:message:messageRecord:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:message:messageRecord:export']">
          <a-icon type="download" />导出
        </a-button>
        <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{float: 'right'}"
          icon="reload"
          @click="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="traceId"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false">
        <span slot="templateName" slot-scope="text, record">
          {{ record.templateName }}({{ record.templateId }})
        </span>
        <span slot="appName" slot-scope="text, record">
          {{ record.appName }}({{ record.appId }})
        </span>
        <span slot="messageStatus" slot-scope="text, record">
         {{ showMsgStatusRecord(record.messageStatus) }}
        </span>
        <span slot="retried" slot-scope="text, record">
         {{ showRetriedRecord(record.retried) }}
        </span>
        <span slot="userType" slot-scope="text, record">
         {{ showUserTypeRecord(record.userType) }}
        </span>
        <span slot="pushRange" slot-scope="text, record">
         {{ showPushRangeRecord(record.pushRange) }}
        </span>
        <span slot="channelType" slot-scope="text, record">
         {{ showChannelTypeRecord(record.channelType) }}
        </span>
        <span slot="messageType" slot-scope="text, record">
         {{ showMessageTypeRecord(record.messageType) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:message:messageRecord:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:message:messageRecord:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:message:messageRecord:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:message:messageRecord:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listMessageRecord, delMessageRecord, exportMessageRecord } from '@/api/message/messageRecord'
import {
  queryMessageTypes
} from '@/api/message/template'
import CreateForm from './modules/CreateForm'

export default {
  name: 'MessageRecord',
  components: {
    CreateForm
  },
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      msgPushScopeOptions: [],
      msgUserTypeOptions: [],
      retriedOptions: [
      {
          dictValue: 1,
          dictLabel: '是'
        },
        {
          dictValue: 0,
          dictLabel: '否'
        }
      ],
      msgStatusOptions: [
        {
          dictValue: 1,
          dictLabel: '成功'
        },
        {
          dictValue: 0,
          dictLabel: '失败'
        }
      ],
      msgChannelTypeOptions: [],
      messageTypeOptions: [],
      total: 0,
      // 查询参数
      queryParam: {
        traceId: null,
        templateId: null,
        appId: null,
        messageStatus: null,
        userType: null,
        pushUser: null,
        channelType: null,
        messageType: null,
        pushRange: null,
        retried: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '消息链路id',
          dataIndex: 'traceId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '模板 id',
          dataIndex: 'templateName',
          scopedSlots: { customRender: 'templateName' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '消息应用id',
          dataIndex: 'appName',
          scopedSlots: { customRender: 'appName' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '消息状态',
          dataIndex: 'messageStatus',
          scopedSlots: { customRender: 'messageStatus' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户类型',
          dataIndex: 'userType',
          scopedSlots: { customRender: 'userType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '消息推送人',
          dataIndex: 'pushUser',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发送渠道类型',
          dataIndex: 'channelType',
          scopedSlots: { customRender: 'channelType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '消息类型',
          dataIndex: 'messageType',
          scopedSlots: { customRender: 'messageType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '推送范围',
          dataIndex: 'pushRange',
          scopedSlots: { customRender: 'pushRange' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否重试消息',
          dataIndex: 'retried',
          scopedSlots: { customRender: 'retried' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发送时间',
          dataIndex: 'createTime',
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getDicts('msg_push_scope').then((response) => {
      this.msgPushScopeOptions = response.data
    })
    this.getDicts('msg_user_type').then((response) => {
      this.msgUserTypeOptions = response.data
    })
    this.getDicts('msg_channel_type').then((response) => {
      this.msgChannelTypeOptions = response.data
    })
    queryMessageTypes().then((response) => {
      this.messageTypeOptions = response.data
    })
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    showRetriedRecord (retried) {
      const dict = this.retriedOptions.find((item) => parseInt(item.dictValue) === retried)
      return dict ? dict.dictLabel : ''
    },
    showMsgStatusRecord (messageStatus) {
      const dict = this.msgStatusOptions.find((item) => parseInt(item.dictValue) === messageStatus)
      return dict ? dict.dictLabel : ''
    },
    showMessageTypeRecord (messageType) {
      const dict = this.messageTypeOptions.find((item) => item.code === messageType)
      return dict ? dict.name : ''
    },
    showChannelTypeRecord (channelType) {
      const dict = this.msgChannelTypeOptions.find((item) => parseInt(item.dictValue) === channelType)
      return dict ? dict.dictLabel : ''
    },
    showUserTypeRecord (userType) {
      const dict = this.msgUserTypeOptions.find((item) => parseInt(item.dictValue) === userType)
      return dict ? dict.dictLabel : ''
    },
    showPushRangeRecord (pushRange) {
      const dict = this.msgPushScopeOptions.find((item) => parseInt(item.dictValue) === pushRange)
      return dict ? dict.dictLabel : ''
    },
    /** 查询消息记录列表 */
    getList () {
      this.loading = true
      listMessageRecord(this.queryParam).then(response => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        traceId: undefined,
        templateId: undefined,
        appId: undefined,
        messageStatus: undefined,
        userType: undefined,
        pushUser: undefined,
        channelType: undefined,
        messageType: undefined,
        pushRange: undefined,
        retried: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.traceId)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const traceIds = row.traceId || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + traceIds + '的数据',
        onOk () {
          return delMessageRecord(traceIds)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          return exportMessageRecord(that.queryParam)
            .then(response => {
              that.download(response.msg)
              that.$message.success(
                '导出成功',
                3
              )
          })
        },
        onCancel () {}
      })
    }
  }
}
</script>
